import React from 'react'
import { createOptimizedCloudinary } from './utils/utilityService'

export const OUR_WORK = [
  {
    imageConfig: {
      src: 'https://i.ibb.co/xfbFG42/smartmockups-kblncy6r.png',
      // createOptimizedCloudinary(
      //   'https://res.cloudinary.com/calderablog/image/upload/v1565066770/its-a-date-phone_izkfva.png',
      // ),
      alt: 'Uberoption dashboard',
    },
    title: 'Uberoption',
    to: '#', // '/case-studies/its-a-date',
    reverse: false,
    description() {
      return (
        <div>
          <p>
            Uberoption brings traders a powerful toolkit for their intraday and global endeavors by picking the type of option that best suits their trading style and profitability expectations.
          </p>
          <p>
            Binary Options is a trading instrument that offers a guaranteed return for a correct prediction about an asset’s price direction within a selected timeframe.
          </p>
          <p>
            Digital Options is a trading instrument that allows you to speculate on the extent of the price change, rather than just on the general price direction.
          </p>
        </div>
      )
    },
  },
  {
    imageConfig: {
      src: 'https://i.ibb.co/9snWMNp/smartmockups-kblnjsxj.png',
      // createOptimizedCloudinary(
      //   'https://res.cloudinary.com/calderablog/image/upload/v1565064208/Image_68_2x_flau11.png',
      // ),
      alt: "Net Loading app launch screen",
    },
    title: "Net Loading",
    to: '#', // '/case-studies/pride-of-the-meadows',
    reverse: true,
    description() {
      return (
        <div>
          <p>
            NetLoading provides 4.0 logistics services, connecting shippers and a network of empty returned truck nationwide.
            NetLoading develops two transport technology products, NetLoading - Shipper and NetLoading - Truck Owner.
          </p>
          <p>
            NetLoading's mission is to digitize the trucking industry, provide a high-quality automatic truck matching system and thus help to save logistics costs for all Vietnamese businesses.
            NetLoading apps bring technology solutions to help businesses search and manage trucks online. On the other side, NetLoading helps truck owners increase up to 30% of income.
          </p>
        </div>
      )
    },
  },
  {
    imageConfig: {
      src: createOptimizedCloudinary(
        'https://res.cloudinary.com/calderablog/image/upload/v1565066031/guess-the-throne-mockup-web_st6rbk.png',
      ),
      alt: 'Guess the Throne landing page',
    },
    title: 'Guess the Throne',
    to: '/case-studies/guess-the-throne',
    reverse: false,
    description() {
      return (
        <>
          <p>
            The team at Caldera was wild about Game of Thrones, so naturally, we
            wanted to get involved in a death pool for the final season. A death
            pool is similar to a March Madness bracket: you select who you think
            out of the major characters will live or die. One point was awarded
            for each correct guess at the end of the season. Potential point
            totals were updated after each round (episode), and he/she with the
            most points at the end of the season won.
          </p>
          <p>
            However, after scouring the internet, we could not find one Death
            Pool which suited our needs.
          </p>
        </>
      )
    },
  },
  {
    imageConfig: {
      src: createOptimizedCloudinary(
        'https://res.cloudinary.com/calderablog/image/upload/v1565065226/Image_45_2x_aoybmp.png',
      ),
      alt: 'Gamebyrd landing page',
    },
    title: 'Gamebyrd',
    to: '/case-studies/gamebyrd',
    reverse: true,
    description() {
      return (
        <p>
          The swift transformation of the video-rental industry (R.I.P
          brick-and-mortar movie rental companies) left a void within the hearts
          of many gamers. As the market moved towards digital entertainment
          streaming, the ability to rent anything from new video games to
          childhood classics was lost.
        </p>
      )
    },
  },
]
